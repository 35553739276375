import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import breaks from 'remark-breaks'
import styled from 'styled-components'

import Layout from '../components/layout'
// import SEO from '../components/seo'

export const query = graphql`
  query singleTerms {
    allAirtable(
      filter: {table: {eq: "AGB"}}
      sort: {order: ASC, fields: rowIndex}
    ) {
      nodes {
        data {
          Type
          Content
        }
      }
    }
  }
`

const isType = (type, term) => {
    if (Array.isArray(type)) {
        return type.includes(term)
    } else {
        return false
    }
}

const TermsPage = (props) => {
    const items = props.data.allAirtable.nodes
    const content = items.map(item => {
        let formattedContent = <ReactMarkdown remarkPlugins={[gfm, breaks]} disallowedElements={['p']} unwrapDisallowed={true}>{item.data.Content}</ReactMarkdown>

        // Avoid <ol> and <ul> in headings
        let formattedHeading = <ReactMarkdown remarkPlugins={[gfm, breaks]} disallowedElements={['p', 'ol', 'ul', 'li']} unwrapDisallowed={true}>{item.data.Content}</ReactMarkdown>

        switch (true) {
            case isType(item.data.Type, 'Kolumne'):
                return <h1 className="kicker">{formattedHeading}</h1>;
            case isType(item.data.Type, 'H2'):
                return <h2>{formattedHeading}</h2>;
            case isType(item.data.Type, 'H4'):
                return <h3>{formattedHeading}</h3>;
            case isType(item.data.Type, 'Fließtext'):
                return <p>{formattedContent}</p>;
        }
    })
    return (
        <TermsContainer>
            <Layout>
                {/* <SEO title="Page two" /> */}
                {content}
            </Layout>
        </TermsContainer>
    )
}

const TermsContainer = styled.div`
    h1 {
        padding-top: 0;
    }
`

export default TermsPage